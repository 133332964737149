import React from 'react'
import { useState, useEffect } from 'react'
import { Tabs } from 'flowbite-react'
import './style-two.css'
// import ErrorToast from "../../toast/error/ErrorToast";
import MyInfo from './singpass-tab-items/MyInfo'
import MyBusiness from './singpass-tab-items/MyBusiness'
import Verify from './singpass-tab-items/Verify'
import { useTranslation } from 'react-i18next'
import { useErrorContext } from '../../../context/ErrorContext'
import LoadSpinner from '../../spinner/Spinner'
import { customApi } from '../../../api/awsConnector'
import { useUserContext } from '../../../context/UserContext'

function SingpassTabs() {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const [selectedTab, setSelectedTab] = useState(1)

  const { setErrorMsg } = useErrorContext()

  const [isLoading, setIsLoading] = useState(false)

  const [currentSingpassValues, setCurrentSingpassValues] = useState({
    myInfoAppID: '',
    myInfoBizAppID: '',
    myInfoBizSharedSecret: '',
    verifyAppID: '',
    verifySharedSecret: '',
  })

  useEffect(() => {
    setIsLoading(true)
    customApi(setErrorMsg, setIsLoading, user, 'portal-getsecrets', { eid: 'singpass' }).then((res) => {
      setCurrentSingpassValues(res)
    })
    setIsLoading(false)
  }, [])

  return (
    <>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <Tabs className="singpass-tabs" style="underline" onActiveTabChange={(tab) => setSelectedTab(tab + 1)}>
          <Tabs.Item active={selectedTab === 1} title={t('secrets.singpass.step1')}>
            <MyInfo currentSingpassValues={currentSingpassValues} setCurrentSingpassValues={setCurrentSingpassValues} />
          </Tabs.Item>

          <Tabs.Item active={selectedTab === 2} title={t('secrets.singpass.step2')}>
            <MyBusiness
              currentSingpassValues={currentSingpassValues}
              setCurrentSingpassValues={setCurrentSingpassValues}
            />
          </Tabs.Item>

          <Tabs.Item active={selectedTab === 3} title={t('secrets.singpass.step3')}>
            <Verify currentSingpassValues={currentSingpassValues} setCurrentSingpassValues={setCurrentSingpassValues} />
          </Tabs.Item>
          <Tabs.Item active={selectedTab === 4} title={t('secrets.singpass.step4')}>
            <h1 className="font-semibold text-[30px]">{t('secrets.under-construction')}</h1>
          </Tabs.Item>
        </Tabs>
      )}
    </>
  )
}

// SingpassTabs.propTypes = {
//   selectedTab: PropTypes.number.isRequired,
//   setSelectedTab: PropTypes.func.isRequired,
//   t: PropTypes.func.isRequired
// };

export default SingpassTabs
