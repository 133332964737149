export const ServicesData = [
  { label: 'Sino Connect', key: 'custom:deploy-sino-connect' },
  { label: 'Sino Check', key: 'custom:deploy-sino-check' },
  { label: 'Sino Company Report', key: 'custom:deploy-sino-cr' },
  { label: 'iAM Smart', key: 'custom:deploy-iamsmart' },
  { label: 'Singpass', key: 'custom:deploy-singpass' },
  { label: 'OCR', key: 'custom:deploy-ocr' },
  { label: 'CRA', key: 'custom:deploy-cra' },
  // { label: 'UAE pass', key: 'custom:deploy-uaepass' },
]
